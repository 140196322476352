<template>
<div class="">
    <div class="container-fluid  py-5" style="background-color: #FFFDEE">
        <div class="container py-2 text-center">
            <div class="row">
                <div class="col-12 textunit">
                    <h1>TIPE UNIT</h1>
                </div>
            </div>
            <div class="row py-3 text-center">
                <div class="col-12 col-lg-4 my-3 my-lg-0" v-for="unit in units" :key="unit.index">
                    <router-link :to="{ name: 'detail_units', params: { slug: unit.slug}}" class="nav-link px-0 mt-0">
                        <div class="">
                            <img :src="'https://adm-estusae.makutapro.id/storage/images/'+unit.img_layout" class="card-img-top" alt="">
                            <div style="background-color:#2E2D2D">
                                <div class="ps-2 pt-1 pita " style="width:220px; height:32px;"><p class="pita1 ">&nbsp;Harga Mulai&nbsp;<span class="pita2"><strong>{{unit.price}}</strong>&nbsp;Jt-an</span></p></div>
                            </div>
                            <div class="card-body bgCard mx-0 my-0">
                                <h5 class="card-title textCard">{{unit.name}}</h5>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>


.bgCard {
    background-color: #2E2D2D;
}

.textCard {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.25em;
    color: #FFFDEE;

}

.textunit {
    font-family: Manuale;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.35em;
    color: #2E2D2D;
}
</style>

<script>
export default {
     name: 'tipe-unit',
     data(){
        return{
            units:[],
            detailUnit:[],
        }
    },
     props:['slug'],
    mounted () {
    window.scrollTo(0, 0)
  },
  created(){
     this.$axios.get('https://adm-estusae.makutapro.id/api/contentunit')
      .then((response) => {
        this.units = response.data.data
       })

    
  }
}
</script>
